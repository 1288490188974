::-webkit-scrollbar {
  width: 6px;
  height: 9px; /* Added height for horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-thumb:horizontal {
  height: 9px; /* Set the height of the horizontal scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
